<template>
  <div class="flex-row-fluid col-lg-9" :class="$vuetify.breakpoint.smAndDown ? 'mt-10' : ''">
    <!--begin::Card-->
    <div class="card card-custom card-stretch">
      <!--begin::Header-->
      <div class="card-header py-3">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder text-dark">Contato</h3>
          <span class="text-muted font-weight-bold font-size-sm mt-1">Atualize suas informações de contato</span>
        </div>
      </div>

      <OverlayLoader :is-submitting="isSubmitting">
        <!--begin::Body-->
        <div class="card-body row">
          <!--begin::Insert Contact-->
          <div class="col-xl-6 border-right">
            <h5 class="font-weight-bold mb-6">Novo contato</h5>
            <!--begin::Form-->
            <b-form class="form" @submit.stop.prevent="onSubmit">
              <!-- Input Cell Phone -->
              <b-form-group label="Tipo:" label-for="type">
                <b-form-select
                  v-model="$v.form.type.selected.$model"
                  :options="form.type.options"
                  class="form-control form-control-solid h-auto py-5 px-6 rounded"
                ></b-form-select>
              </b-form-group>

              <!-- Input Phone Fix -->
              <b-form-group label="Número">
                <the-mask
                  v-model="$v.form.number.$model"
                  mask="(##) #####-####"
                  class="form-control form-control-solid h-auto py-5 px-6 rounded"
                />

                <b-form-invalid-feedback>O campo número é obrigatório.</b-form-invalid-feedback>
              </b-form-group>

              <!--begin::Action-->
              <div class="form-group d-flex justify-content-center">
                <button
                  ref="kt_login_signup_submit"
                  type="submit"
                  class="btn btn-success font-weight-bold px-12 py-4 my-3 font-size-3 mx-4"
                >
                  Salvar Contato
                </button>
              </div>
              <!--end::Action-->
            </b-form>

            <!--begin::End Form-->
          </div>
          <!--begin::End Insert Contact-->

          <!--begin::List Contacts-->
          <div class="col-xl-6">
            <h5 class="font-weight-bold mb-6">Contatos cadastrados</h5>
            <b-card no-body title="Title" header-bg-variant="dark" header-tag="header">
              <b-card-body style="padding: 0">
                <table class="table table-hover table-striped m-0">
                  <thead class="thead-dark">
                    <tr>
                      <th scope="col">Tipo</th>
                      <th scope="col">Número</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(contact, i) in currentContacts" :key="i">
                      <td>{{ contact.ecosystem_contact_type_name }}</td>
                      <td>{{ contact.value | VMask('(##)#####-####') }}</td>
                      <td>
                        <a
                          href="#"
                          class="btn btn-icon btn-light btn-hover-danger btn-sm mx-3"
                          @click="deleteContact(contact.id, i, $event.target)"
                        >
                          <span class="svg-icon svg-icon-md svg-icon-danger">
                            <inline-svg src="/media/svg/icons/General/Trash.svg" />
                          </span>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-card-body>
            </b-card>
          </div>
          <!--begin::List Contacts-->
        </div>
        <!--end::Body-->
      </OverlayLoader>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { TheMask } from 'vue-the-mask';
import OverlayLoader from '@/shared/content/OverlayLoader.vue';

export default {
  components: { TheMask, OverlayLoader },
  mixins: [validationMixin],
  data() {
    return {
      isSubmitting: false,
      form: {
        type: {
          selected: '',
          options: [
            { value: 1, text: 'Telefone Fixo' },
            { value: 2, text: 'Telefone Celular' }
          ]
        },
        number: ''
      }
    };
  },
  computed: mapGetters(['currentContacts']),
  validations: {
    form: {
      type: {
        selected: {
          required
        }
      },
      number: {
        required
      }
    }
  },
  async mounted() {
    this.$store.dispatch('SET_BREADCRUMB', [{ title: 'Perfil' }, { title: 'Contato' }]);
  },
  methods: {
    async onSubmit() {
      this.isSubmitting = true;

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const submitButton = this.$refs['kt_login_signup_submit'];
      submitButton.classList.add('spinner', 'spinner-light', 'spinner-right');

      const form = {
        ecosystem_contact_type_id: this.$v.form.type.selected.$model,
        value: this.$v.form.number.$model
      };

      // send register request
      await this.$store
        .dispatch('INSERT_CONTACT', form)
        .then((response) => {
          this.$swal({
            title: response.message,
            icon: 'success'
          });
          this.isSubmitting = false;
          submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right');

          this.resetForm();
        })
        .catch(() => {
          this.isSubmitting = false;
          submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right');
        });
    },
    async deleteContact(id, index) {
      this.isSubmitting = true;
      // send register request
      await this.$store
        .dispatch('DELETE_CONTACT', id)
        .then((response) => {
          //modal succcess
          this.$swal({
            title: response.message,
            icon: 'success'
          });
          //
          this.currentContacts.splice(index, 1);
          this.isSubmitting = false;
        })
        .catch(() => {});
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form.number = '';

      this.$nextTick(() => {
        this.$v.$reset();
      });
    }
  }
};
</script>
